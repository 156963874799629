
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'subMenus',
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup() {
    const menusCls = (item: any) => {
      let name = ''
      if (item.type === 'bian') name = 'bian'
      if (item.type === 'zhang') name = 'zhang'
      if (item.type === 'jie') name = 'jie'
      if (item.type === 'xuyan_title') name = 'xuyan_title'
      return name
    }
    return {
      menusCls
    }
  }
})
