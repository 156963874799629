
import { ref, defineComponent } from 'vue'
import { ArrowUp, ArrowDown } from '@element-plus/icons-vue'
import { IComObj } from '@/global/types'

export default defineComponent({
  name: 'TagPanel',
  components: {
    ArrowUp,
    ArrowDown
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup(props: any) {
    const IData = ref<IComObj>([])
    IData.value = props.data
    const moreFlag = ref(false)

    const handlerMore = () => {
      moreFlag.value = !moreFlag.value
    }
    return {
      IData,
      moreFlag,
      handlerMore
    }
  }
})
