
import { defineComponent, ref, watch, PropType } from 'vue'
import { IComObj } from '@/global/types'
export default defineComponent({
  emits: ['myclick'],
  props: {
    data: {
      type: Array as PropType<IComObj>,
      default: () => {
        return []
      }
    },
    total: {
      type: Number,
      defalut: null
    },
    label: {
      type: String,
      defalut: ''
    }
  },
  setup(props: any, { emit }) {
    const IData = ref<IComObj>([])
    const ITotal = ref()
    const myclick = () => {
      emit('myclick')
    }
    watch(
      () => props.data,
      () => {
        IData.value = props.data
        ITotal.value = props.total
      }
    )
    return { IData, myclick, ITotal }
  }
})
