import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-338d1098"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TagPanel = _resolveComponent("TagPanel")!
  const _component_SubRules = _resolveComponent("SubRules", true)!
  const _component_RelativeItem = _resolveComponent("RelativeItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sub-rules", _ctx.isZywj?'ql-editor':''])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createElementVNode("div", {
          class: _normalizeClass(['law_item',`type_${item.type || 'default'}`,item.itemShow || _ctx.parentItemShow?'active':''])
        }, [
          (_ctx.pureId === '1')
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                innerHTML: item.name
              }, null, 8 /* PROPS */, _hoisted_1))
            : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.dynamicContent(item.name)), {
                key: 1,
                lawSummary: _ctx.lawSummary
              }, null, 8 /* PROPS */, ["lawSummary"]))
        ], 2 /* CLASS */),
        (_ctx.pureId === '2' && item.tag && item.tag.length)
          ? (_openBlock(), _createBlock(_component_TagPanel, {
              key: 0,
              data: item.tag
            }, null, 8 /* PROPS */, ["data"]))
          : _createCommentVNode("v-if", true),
        (item.imgs)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(item.imgs, (o, i) => {
              return (_openBlock(), _createElementBlock("img", {
                key: i,
                src: o
              }, null, 8 /* PROPS */, _hoisted_2))
            }), 128 /* KEYED_FRAGMENT */))
          : _createCommentVNode("v-if", true),
        (item.children.length)
          ? (_openBlock(), _createBlock(_component_SubRules, {
              key: 2,
              "pure-id": _ctx.pureId,
              data: item.children,
              parentItemShow: item.itemShow || _ctx.parentItemShow
            }, null, 8 /* PROPS */, ["pure-id", "data", "parentItemShow"]))
          : _createCommentVNode("v-if", true),
        (_ctx.pureId == '3')
          ? (_openBlock(), _createBlock(_component_RelativeItem, {
              key: 3,
              itemUid: item.itemUid,
              onShow: ($event: any) => {item.itemShow = true;}
            }, null, 8 /* PROPS */, ["itemUid", "onShow"]))
          : _createCommentVNode("v-if", true)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 2 /* CLASS */))
}