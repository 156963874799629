import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2a71306c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lazy_panel = _resolveComponent("lazy-panel")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.type != 'fyCaseLibrary')
      ? (_openBlock(), _createBlock(_component_lazy_panel, {
          key: 0,
          label: _ctx.type == 'caseLibrary'?'相关案例':'相关法规',
          data: _ctx.RelatedLaws,
          total: _ctx.RelatedLawsTotal,
          onMyclick: _ctx.relateMoreClick
        }, null, 8 /* PROPS */, ["label", "data", "total", "onMyclick"]))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_lazy_panel, {
      label: '引用法规',
      data: _ctx.referenceLaws,
      total: _ctx.referenceLawsTotal,
      onMyclick: _ctx.referenMoreClick
    }, null, 8 /* PROPS */, ["data", "total", "onMyclick"]),
    (_ctx.REQUIRE_LOGIN == 1)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tools, (item, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, [
              _createElementVNode("div", {
                class: "column",
                style: _normalizeStyle({
            backgroundImage:
              `url('` + require(`@/assets/images/detailPage/${item.img}`) + `')`
          }),
                onClick: ($event: any) => (_ctx.handleClick(item))
              }, [
                _createElementVNode("span", null, _toDisplayString(item.text), 1 /* TEXT */)
              ], 12 /* STYLE, PROPS */, _hoisted_2)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}