import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5de6b7ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item" }
const _hoisted_2 = { class: "item-label" }
const _hoisted_3 = { class: "item-content" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NoData = _resolveComponent("NoData")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.label), 1 /* TEXT */),
      _createElementVNode("ul", _hoisted_3, [
        (!_ctx.IData.length && _ctx.ITotal != null)
          ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
              _createVNode(_component_NoData)
            ]))
          : _createCommentVNode("v-if", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.IData, (item, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            title: item.name
          }, [
            _createVNode(_component_router_link, {
              to: {
              name: _ctx.$route.name == 'FyCaseDetail'?'DetailPage':_ctx.$route.name,
              query: { id: item.id }
            },
              target: "_blank"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
          ], 8 /* PROPS */, _hoisted_5))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _createCommentVNode(" <div\n        class=\"loading\"\n        v-if=\"IData.length < Number(ITotal)\"\n        @click=\"myclick()\"\n      >\n        点击加载更多\n      </div> ")
    ])
  ]))
}