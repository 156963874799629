/*
 * @Author: wangjie
 * @Date: 2022-07-15 18:49:51
 * @Description: 切换皮肤data
 */

// 三套主题
export const theme = [
  { BackColor: '#ffffff', borderBtm: '#015293', cardColor: '#f7f8fa' },
  { BackColor: '#fffbf3', borderBtm: '#f6c580', cardColor: '#fff6e4' },
  { BackColor: '#F8FFF4', borderBtm: '#d0e2c4', cardColor: '#f1f8ec' }
]
