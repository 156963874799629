// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/detailPage/icon-title.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".item[data-v-5de6b7ba] {\n  margin-bottom: 20px;\n  background: #ffffff;\n  border-radius: 10px;\n  padding-bottom: 20px;\n}\n.item .item-label[data-v-5de6b7ba] {\n  height: 48px;\n  font-size: 18px;\n  padding-left: 10px;\n  display: flex;\n  align-items: center;\n}\n.item .item-label[data-v-5de6b7ba]::before {\n  content: '';\n  display: inline-block;\n  width: 23px;\n  height: 19px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  margin-right: 7px;\n}\n.item .item-content[data-v-5de6b7ba] {\n  border-top: 2px solid #f5f5f5;\n  padding: 10px;\n  min-height: 220px;\n  max-height: 950px;\n  overflow-y: auto;\n}\n.item .item-content li[data-v-5de6b7ba] {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  line-height: 40px;\n  padding-left: 10px;\n}\n.item .item-content li a[data-v-5de6b7ba] {\n  color: #666666;\n}\n.item .item-content li a[data-v-5de6b7ba]:hover {\n  color: #1c7fef;\n}\n.item .loading[data-v-5de6b7ba] {\n  color: #999999;\n  text-align: center;\n  font-size: 14px;\n  height: 30px;\n  line-height: 30px;\n  position: relative;\n  cursor: pointer;\n}\n.item .loading[data-v-5de6b7ba]:hover {\n  color: #1c7fef;\n}\n", ""]);
// Exports
module.exports = exports;
