// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/tag-icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".tag-list[data-v-34c5ce27] {\n  margin-bottom: 15px;\n}\n.tag-icon[data-v-34c5ce27] {\n  flex: 0 0 10px;\n  width: 10px;\n  height: 10px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n  background-size: 100% 100%;\n  position: relative;\n  left: -10px;\n  top: 8px;\n}\n.line[data-v-34c5ce27] {\n  margin-bottom: 8px;\n  display: flex;\n}\n.tagClass[data-v-34c5ce27] {\n  margin-bottom: 5px;\n  margin-right: 8px;\n  border: none;\n}\n.tag-info[data-v-34c5ce27] {\n  display: flex;\n  padding-left: 2em;\n}\n.tag-more[data-v-34c5ce27] {\n  display: flex;\n  background: #fff;\n  box-shadow: 0 0 10px rgba(33, 33, 33, 0.15);\n  border-radius: 10px;\n  padding: 25px 30px 15px;\n}\n.tag-type[data-v-34c5ce27] {\n  margin-right: 5px;\n  font-size: 14px;\n  width: 70px;\n  line-height: 26px;\n}\n.tag-box[data-v-34c5ce27] {\n  flex: 1;\n}\n.tag-info .tag-box[data-v-34c5ce27] {\n  flex: none;\n}\n.tag-up[data-v-34c5ce27] {\n  display: block;\n  margin: 0 auto;\n}\n", ""]);
// Exports
module.exports = exports;
