
import { ref, watch, defineComponent, nextTick } from 'vue'
import { Search, ArrowDown, ArrowUp } from '@element-plus/icons-vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    Search,
    ArrowDown,
    ArrowUp
  },
  emits: ['change', 'matchChange'],
  props: {
    noCatalog: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    matchKey: {
      type: Number,
      default: 0
    },
    matchArrLength: {
      type: Number,
      default: 0
    }
  },
  setup(props: any, { emit }) {
    const route = useRoute()
    const inputSearch = ref('')
    const menuList = ref<any[]>([])
    const matchIndex = ref(props.matchKey)
    const matchLength = ref(props.matchArrLength)
    const matchFlag = ref(false)
    const listFlag = ref(false)
    const showFlag = () => {
      listFlag.value = true
    }
    const filterData = (data: any) => {
      data.forEach((item: any) => {
        let obj = {}
        if (item.type === 'bian' || item.type === 'zhang' || item.type === 'jie') {
          if (item.name.split("id='")[1]) {
            let href = item.name.split("id='")[1].split("'>")[0]
            let name =
              item.name.split("'>")[1].split('</h5>')[0] +
              ' ' +
              item.name.split("'>")[1].split('</h5>')[1]
            obj = {
              type:item.type,
              name: name.replace('<span>', '').replace('</span>', ''),
              id: href
            }
            menuList.value.push(obj)
          }
        } else if (item.type === 'tiao' && item.name) {
          if (item.name === null) item.name = ''
          if (item.name.split("id='")[1]) {
            let href = item.name.split("id='")[1].split("'>")[0]
            let name = item.name.split("'>")[1].split('</h5>')[0]
            obj = {
              type:item.type,
              name: name,
              id: href
            }
            menuList.value.push(obj)
          }
        }
        item.children && filterData(item.children)
      })
    }

    const handledropItemClick = (id: any) => {
      document.querySelectorAll('.menu-match-b').forEach((item: any) => {
        item.classList.remove('menu-match-b')
      })
      const returnEle: HTMLElement = document.getElementById(id) as HTMLElement
      if (returnEle) {
        setTimeout(() => {
          // 目录跳转高亮整条
          if (returnEle.parentElement?.classList.value === 'tiao') {
            returnEle.parentElement.parentElement?.classList.add('menu-match-b')
          } else {
            returnEle.parentElement?.classList.add('menu-match-b')
          }

          document.documentElement.scrollTop = returnEle.offsetTop
        }, 500)
      }
    }
    const searchValue = ref<string[]>([])
    let keyword: any = []
    if (route.query.keyword) {
      if (typeof route.query.keyword === 'string') {
        keyword = [route.query.keyword]
      } else {
        keyword = route.query.keyword as string[]
      }
    }
    const changeValue = (e: any = {}) => {
      matchIndex.value = 0
      let inputArr = inputSearch.value ? inputSearch.value.split(' ') : []
      emit('change', inputArr)
      matchFlag.value = !!inputSearch.value
    }

    if (keyword.length) {
      searchValue.value = keyword
      inputSearch.value = searchValue.value.join(' ')
      changeValue()
    } else {
      searchValue.value = []
    }

    const changeMatchPosition = (type: string) => {
      if (matchLength.value === 0) return
      if (type === 'next') {
        matchIndex.value++
        if (matchIndex.value >= matchLength.value) matchIndex.value = 0
      } else if (type === 'prev') {
        if (matchIndex.value === 0) matchIndex.value = matchLength.value
        if (matchIndex.value < 0) matchIndex.value = matchLength.value - 1
        matchIndex.value--
      }
      emit('matchChange', matchIndex.value)
    }

    watch(
      () => props.data,
      () => {
        menuList.value = []
        filterData(props.data)
        nextTick(() => {
          if (route.query.paraId) {
            handledropItemClick(route.query.paraId)
          }
        })
      }
    )
    watch(
      () => props.matchArrLength,
      (newValue) => {
        matchLength.value = newValue
      }
    )
    watch(
      () => props.matchKey,
      (newValue) => {
        matchIndex.value = newValue
      }
    )

    return {
      matchIndex,
      matchLength,
      matchFlag,
      inputSearch,
      changeValue,
      menuList,
      handledropItemClick,
      changeMatchPosition,
      showFlag,
      listFlag
    }
  }
})
