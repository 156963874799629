/*
 * @Author: zhaohongyang
 * @Date: 2022-04-11 19:08:26
 * @Description: 关键词检索
 */
interface IObj {
  name: string
  start: number
  end: number
  index: number
  key: number
}
export class Key {
  private _key = 1
  public constructor(key = 1) {
    this._key = key
  }
  public setKey(value: number) {
    // 新的关键词检索时，重置key值
    this._key = value
    key = keyClass.getKey()
  }
  public getKey(): number {
    return this._key
  }
}
const keyClass = new Key()
let key = keyClass.getKey()

export function matchStr(text: string, wordArr: string[], matchArr: any[]) {
  const objArr: IObj[] = []
  const keyArr: number[] = []
  for (let i = 0, length = wordArr.length; i < length; i++) {
    matchFn(text, wordArr[i], objArr, keyArr)
  }
  objArr.sort((a: IObj, b: IObj) => {
    return a.index - b.index
  })
  matchArr.push(...objArr)
  let strL = 0
  let nameL = 0
  if (objArr.length) {
    objArr.forEach((o: IObj, i: number) => {
      o.key = keyArr[i]
      if (i === 0) {
        text = changeStr(
          text,
          o.start,
          o.end,
          o.name,
          `<b name="${o.key}">${o.name}</b>`
        )
      } else {
        text = changeStr(
          text,
          o.start + strL - nameL,
          o.end + strL - nameL,
          o.name,
          `<b name="${o.key}">${o.name}</b>`
        )
      }
      nameL += o.name.length // 记录匹配字符串的长度，在下次匹配索引时减掉该长度
      strL += `<b name="${o.key}">${o.name}</b>`.length // 记录替换后的字符串长度
    })
  }

  return text
}
/**
 * @param allstr 原始字符串
 * @param start 开始位置
 * @param end 结束位
 * @param str 要改变的字
 * @param changeStr 改变后的字
 * @returns
 */
function changeStr(
  allstr: string,
  start: number,
  end: number,
  str: string,
  changeStr: string
) {
  if (allstr.substring(start, end) == str) {
    return (
      allstr.substring(0, start) +
      changeStr +
      allstr.substring(end, allstr.length)
    )
  } else {
    return allstr
  }
}
/**
 * @param str 目标字符串
 * @param matchs 匹配字符串
 * @param resArr 输出的结果
 */

function matchFn(str: string, matchs: string, resArr: any[], keyArr: number[]) {
  const arr1 = (str||'').split('')
  const arr2 = (matchs||'').split('')
  for (let i = 0, length = arr1.length; i < length; i++) {
    if (matchs[0] === arr1[i]) {
      let num = 0
      // eslint-disable-next-line no-inner-declarations
      function ccc(arr2: string[]) {
        if (arr2[num] && arr1[i + num] && arr2[num] === arr1[i + num]) {
          if (num === matchs.length - 1) {
            const obj = {
              key: key,
              start: i,
              end: i + num + 1,
              index: i,
              name: matchs
            }
            keyArr.push(key)
            key++
            resArr.push(obj)
          }
          num++
          ccc(arr2)
        } else {
          return
        }
      }
      ccc(arr2)
    }
  }
}

/**
* 动画垂直滚动到页面指定位置
* @param { Number } currentY 当前位置
* @param { Number } targetY 目标位置
* const currentY =document.documentElement.scrollTop || document.body.scrollTop
  scrollAnimation(currentY, returnEle.offsetTop - 50)
*/
function scrollAnimation(currentY: any, targetY: any) {
  // 获取当前位置方法
  // const currentY = document.documentElement.scrollTop || document.body.scrollTop

  // 计算需要移动的距离
  const needScrollTop = targetY - currentY
  let _currentY = currentY
  setTimeout(() => {
    // 一次调用滑动帧数，每次调用会不一样
    const dist = Math.ceil(needScrollTop / 10)
    _currentY += dist
    window.scrollTo(_currentY, currentY)
    // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
    if (needScrollTop > 10 || needScrollTop < -10) {
      scrollAnimation(_currentY, targetY)
    } else {
      window.scrollTo(_currentY, targetY)
    }
  }, 1)
}

/**
 * @Author: zhaohongyang
 * @Date: 2022-04-13 17:00:15
 * @Description: 详情页链接跳转新页面
 *
 * @param ref 目标dom节点
 * @param tag 检索的标签，如为获取本例里h6标签中的id
 * @param router
 */

export function jumpNewPage(ref: any, tag: string, router: any) {
  const aa = ref.querySelectorAll(tag)
  aa.forEach((a: any) => {
    a.onclick = function () {
      jumpFn(a.dataset.id, router)
    }
  })
}
export function jumpFn(id: string, router: any) {
  const { href } = router.resolve({
    name: 'DetailPage',
    query: {
      id: id
    }
  })
  window.open(href, '_blank')
}

// 正则完全匹配
const replFn = (str: string, fv: string): string => {
  return str.replace(RegExp(fv, 'g'), `<b>${fv}</b>`)
}
// 选中一段文字弹出菜单

