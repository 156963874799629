
import { defineComponent, ref, watch } from 'vue'
import { IComObj } from '@/global/types'
import { legislateRequest } from '@/service'
import { useRoute } from 'vue-router'
import qs from 'qs'
export default defineComponent({
  name: 'FoldPanel',
  props: {
    data: {
      type: Array,
      default: () => {
        return null
      }
    },
    curColor: {
      type: String,
      default: ''
    },
    //区分历史沿革和上位法
    historyFlag: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    }
  },
  setup(props: any) {
    const IData = ref<IComObj>([])
    const backRef = ref()
    const slotH = ref(0)
    const backFlag = ref(false)
    const backChange = () => {
      backFlag.value = !backFlag.value
    }

    watch(
      () => props.data,
      () => {
        slotH.value = props.data.length
        IData.value = props.data.map((item:any)=>{
          if(!item.time){
            var index = item.name.indexOf('（')
            item.time = item.name.slice(index+1,index+5)+'年'
          }
          return item
        })
      }
    )
    const loading = ref(false)
    const route = useRoute()
    const handleCompare = (item) => {
      loading.value = true
      // legislateRequest.post({
      //   url:'addFromFzzyk',
      //   data: qs.stringify({
      //     currentLawVersionId: item.id,
      //     previousLawVersionId: route.query.id,
      //   }),
      //   headers:{
      //     "Content-Type": "application/x-www-form-urlencoded"
      //   }
      // }).then(res=>{
      //   window.open(process.env.VUE_APP_LEGISLATE_URL+'/version-compare-v2/detail?id='+res.data)
      // }).finally(() => {
      //   loading.value = false
      // })

      let clickItemIndex = IData.value.findIndex(ele => ele.id == item.id);
      let currentIndex = IData.value.findIndex(ele => ele.id == route.query.id);
      debugger
      let previousLawVersionId = currentIndex > clickItemIndex ? route.query.id : item.id;
      let currentLawVersionId = currentIndex <= clickItemIndex ? route.query.id : item.id;
      window.open(process.env.VUE_APP_LEGISLATE_URL+`/version-compare-v2/fzzyk?previousLawVersionId=${previousLawVersionId}&currentLawVersionId=${currentLawVersionId}`)
      loading.value = false
    }
    return { IData, backRef, backFlag, backChange, slotH, handleCompare,loading }
  }
})
